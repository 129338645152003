




































































import { computed, defineComponent } from "@vue/composition-api";
import { toUserDateTimeFormat } from "@/apps/core/modules/utils/datetime";
import { ModalProgrammatic as modal } from "buefy";
import { isNasabah, isPengelola } from "@/apps/accounts/modules/store";
import { formatRupiah } from "@/apps/core/modules/utils/text";

export default defineComponent({
  name: "PengelolaPengajuanListBox",
  props: {
    pengajuan: { type: Object, required: true },
  },
  components: {
    StatusTag: () => import("@/apps/core/components/StatusTag.vue"),
  },
  setup(props) {
    const imgUrl = computed(() => {
      if (props.pengajuan.pengajuan) {
        const jenis = props.pengajuan.pengajuan.jenis.toLowerCase();
        return require(`@/apps/tabungan/assets/img/simp_${jenis}.png`);
      }
      return require("@/apps/tabungan/assets/img/simp_pokok.png");
    });

    const showImage = () => {
      return modal.open(
        `<p class="image"><img src="${props.pengajuan.mandiri.bukti}"></p>`
      );
    };
    return {
      // Data
      detailUrl: "/pengajuan",

      // Computed
      imgUrl,
      isNasabah,
      isPengelola,

      // Method
      formatRupiah,
      showImage,
      toUserDateTimeFormat,
    };
  },
});
